.bordered {
    border: dashed 2px;
    stroke-dashoffset: 12px;
    stroke-width: 1px;
    stroke-dasharray:3 3;    
    border-color: #007BC1;
    width: 25vw;
    height: 30vh;
    border-radius: 8px;
    position: relative;
    margin-top: 13vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    flex-direction: column
  }

  .image {
  display: block;
  max-width:30vw;
  max-height:30vh;
  width: auto;
  height: auto;
  }
  .image-mobileView {
  display: block;
  max-width:60vw;
  max-height:60vh;
  width: auto;
  height: auto;
  }
  
  .mobil-view-bordered {
    border: dashed 2px;
    stroke-dashoffset: 12px;
    stroke-width: 1px;
    stroke-dasharray:3 3;    
    border-color: #007BC1;
    width: 70vw;
    height: 40vh;
    border-radius: 8px;
    position: relative;
    margin-top: 6vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    flex-direction: column
  }