.toggle-switch {
    position: relative;
    display: inline-block;
    width: 25px;
    height: 14px;
  }
  .toggle-switch input[type="checkbox"] {
    display: none;
  }
  .toggle-switch .switch {
    position: absolute;
    cursor: pointer;
    background-color: #C6CACC;
    border-radius: 25px;
    top: 0px;
    right: 0;
    bottom: 0;
    left: 0;
    transition: background-color 0.2s ease;
  }
  .toggle-switch .switch::before {
    position: absolute;
    content: "";
    left: -10px;
    top: -3px;
    width: 20px;
    height: 20px;
    background-color: #fff;
    border-radius: 50%;
    transition: transform 0.2s ease;
    box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.2), 
    0px 1px 1px rgba(0, 0, 0, 0.14), 
    0px 1px 3px rgba(0, 0, 0, 0.12) ;
  }
  .toggle-switch input[type="checkbox"]:checked + .switch::before {
    transform: translateX(25px);
    background-color: #007BC1;
  }
  .toggle-switch input[type="checkbox"]:checked + .switch {
    background-color: #80bbe6;
  }