.check-hide input[type="checkbox"] {
    display: none;
  }
  .arrow-down {
    border: solid #727E84;
    border-width: 0 3px 3px 0;
    width: 10px;
    height: 10px;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
 .arrow-up {
    border: solid #727E84;
    border-width: 0 3px 3px 0;
    display: inline-block;
    width: 10px;
    height: 10px;
    padding: 3px;
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }
  
  
  .up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }
  
  .down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }