html,
body,
#root {
    height: 100%;
    margin: 0;
    padding: 0;
}

/* Login page required master class Starts here */



/* applying background image with blue color */

#root>div {

    background-color: #007bc1 !important;

    background-image: url(assets/images/Background.png) !important;

    background-size: cover !important;

    background-repeat: no-repeat !important;

}



/* applying gradient background */

form>div {

    width: 100% !important;

    height: 20% !important;

    padding: 10% !important;

    background-image: linear-gradient(360deg, #FFFFFF 22.4%, rgba(255, 255, 255, 0.2) 100%), url(assets/images/Tile.png) !important;

    background-size: cover !important;

    background-repeat: no-repeat !important;

}



input::-ms-reveal,

input::-ms-clear {

    display: none;

}



/* Login page required master class Ends here */