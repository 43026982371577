#main {
    height: 100%;
    width: 100%;
    background-color: #007bc1 !important;

    background-image: url(../assets/images/Background.png) !important;

    background-size: cover !important;

    background-repeat: no-repeat !important;
}