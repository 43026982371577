.button-drop-down{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid rgba(66, 78, 84, 0.12);
    border-radius: 4px;
    box-sizing: border-box;
    height: 56px;
}

.button-text{
    padding-top: 6px;
    padding-bottom: 6px;
}